import React from "react";
import queryString from "query-string";
import SEO from "@src/components/SEO";
import { useRedirectHandler } from "@src/utils/useRedirectHandler";
import window from "global/window";
import DojoIslandsOpengraphImage from "@src/assets/images/dojo-islands-opengraph.jpg";
import { incrementMetric } from "@src/utils/logClient";

export const DojoIslandsShareLinkPlayV2 = () => {
  const parsedLocation = queryString.parse(window?.location?.search || "");
  const { token } = parsedLocation;

  useRedirectHandler(() => {
    if (token) {
      return `https://student.classdojo.com/#/playV2?token=${token}`;
    } else {
      incrementMetric("external_site.universal_link.fallback", { component: "DojoIslandsShareLinkPlayV2" });
      return `https://student.classdojo.com/#/playError`;
    }
  });

  return (
    <>
      <SEO noindex={true} image={DojoIslandsOpengraphImage} />
    </>
  );
};
export default DojoIslandsShareLinkPlayV2;
